export default [{
  path: '/auth/signin',
  name: 'auth-signin',
  component: () => import(/* webpackChunkName: "auth-signin" */ '@/pages/auth/SigninPage.vue'),
  meta: {
    layout: 'auth'
  }
},
{
  path: '/error/not-found',
  name: 'error-not-found',
  component: () => import(/* webpackChunkName: "error-not-found" */ '@/pages/error/NotFoundPage.vue'),
  meta: {
    layout: 'error'
  }
},
{
  path: '/tools/filescloud',
  name: 'files-cloud',
  component: () => import('@/pages/Files/files.vue')
},
{
  path: '/tools/shorturl',
  name: 'short-url',
  component: () => import('@/pages/Urls/url.vue')
},
{
  path: '/tools/shorturl/detai',
  name: 'detail-short-url',
  component: () => import('@/pages/Urls/DetailUrl.vue')
},
{
  path: '/tools/agendas',
  name: 'empty-agenda',
  component: () => import('@/pages/Agendas/agendaEmpty.vue')
},
{
  path: '/tools/agendas/:agendaId?',
  name: 'contactos-agenda',
  component: () => import('@/pages/Agendas/agenda.vue')
},
{
  path: '/tools/agendas/:agendaId/contacts',
  name: 'contacts',
  component: () => import('@/pages/Agendas/contacts.vue'),
  props: true
},
{
  path: '/bots',
  name: 'bots',
  component: () => import('@/pages/bots/bots.vue')
},
{
  path: '/companies',
  name: 'companies',
  component: () => import('@/pages/Companies/Companies.vue')
},
{
  path: '/channels/vendors',
  name: 'channels-vendors',
  component: () => import('@/pages/Channels/channels.vue')
},
{
  path: '/document/crear',
  name: 'documentacion-crear',
  component: () => import('@/pages/Documentacion/CrearDoc.vue')
},
{
  path: '/document/crearotp',
  name: 'documentacion-crear-otp',
  component: () => import('@/pages/Documentacion/CrearDocOtp.vue')
},
{
  path: '/document/reporte',
  name: 'documentacion-reporte',
  component: () => import('@/pages/Documentacion/ReporteDoc.vue')
},
{
  path: '/document/creditos',
  name: 'documentacion-creditos',
  component: () => import('@/pages/Documentacion/Creditos.vue')
},
{
  path: '/company/:companyId/mailing_sender',
  name: 'mailing_sender',
  component: () => import('@/pages/Companies/MailingSender.vue')
},
{
  path: '/company/:companyId/whatsapp_sources',
  name: '/whatsapp-sources',
  component: () => import('@/pages/Companies/WhatsappSources.vue')
},
{
  path: '/management/:user_chat_rol/chat/workgroup/:workgroup_id/:agent_name/:supervised_agent_id',
  component: () => import('@/pages/Chat/ChatApp.vue'),
  children: [
    {
      path: '',
      name: 'workgroup-supervisor-chat-general',
      components: {
        default: () => import('@/pages/Chat/InitChat.vue'),
        drawer: () => import('@/pages/Chat/pages/ticketsDrawer.vue')
      },
      exact: true
    }, 
    {
      path: 'ticket/:ticket_id',
      name: 'workgroup-supervisor-chat-channel',
      components: {
        default: () => import('@/pages/Chat/pages/ChatTicketPage.vue'),
        drawer: () => import('@/pages/Chat/pages/ticketsDrawer.vue')
      }
    }
  ]
}, 
//Agente
{
  path: 'tools/:user_chat_rol/chat',
  component: () => import('@/pages/Chat/ChatApp.vue'),
  children: [
    {
      path: '',
      components:
      {
        default: () => import('@/pages/Chat/InitChat.vue'),
        drawer: () => import('@/pages/Chat/pages/ticketsDrawer.vue')
      },
      name:'workgroup-agent-chat-general'
    }, 
    {
      path: 'ticket/:ticket_id',
      name: 'workgroup-agent-chat-channel',
      components:{ 
        default: () => import('@/pages/Chat/pages/ChatTicketPage.vue'),
        drawer: () => import('@/pages/Chat/pages/ticketsDrawer.vue')

      }
    }
  ]
}
]
