import { ValidationError, RequestError, SearchError } from '@/services/errorHandler.service'
import BackendApi from '@/services/backend.service'

//falta declarar este modulo????
const state = {
  isUploadingFile: null,
  isExpandMultimediaMessageDialog: false,
  uploadedFile: null,
  isDownloadingFile: false
}

const getters = {}

const mutations = {
  SET_IS_UPLOADING_FILE(state, payload) {
    state.isUploadingFile = payload
  },
  SET_IS_EXPAND_MULTIMEDIA_MESSAGE_DIALOG(state, payload) {
    state.isExpandMultimediaMessageDialog = payload
  },
  SET_UPLOADED_FILE(state, payload) {
    state.uploadedFile = payload
  },
  SET_IS_DOWNLOADING_FILE(state, payload) {
    state.isDownloadingFile = payload
  }
}

const actions = {
  async uploadFile({ commit }, { file, type }) {
    commit('SET_IS_UPLOADING_FILE', true)
    commit('SET_IS_EXPAND_MULTIMEDIA_MESSAGE_DIALOG', true)
    try {
      if (!file) throw new ValidationError('No se ha proporcionado el archivo a subir', { file })
      if (file.size > 4000000) throw new ValidationError('Se ha excedido el tamaño mámixo', { size: selected_file.size })

      const formData = new FormData()

      formData.append('file', file)
      formData.append('name', file.name)
      
      const response = await BackendApi.post('/uploadFile', formData)

      if (!response || !response.data || !response.data.data ) {
        throw new ValidationError('Error del backend, no se encontró respuesta', { response })  
      }
      const uploadedFile = response.data.data

      if (!uploadedFile) {
        throw new ValidationError('No se ha logrado recuperar el arhchivo subido', { file, formData, uploadedFile })
      }

      if (type === 'visualMedia') {
        uploadedFile.type = file.type === 'video/mp4' ? 'video' : 'image'
      } else if (type === 'file') {
        uploadedFile.type = type 
      } else {
        throw new ValidationError('No se ha proporcionado el type', { type })
      }

      commit('SET_IS_UPLOADING_FILE', false)
      commit('SET_UPLOADED_FILE', uploadedFile)
    } catch (error) {
      commit('SET_IS_UPLOADING_FILE', false)
      if (error instanceof ValidationError) {
        console.error('ValidationError', error, error.details)
      } else {
        console.error(error, { file })
      }
    }
  },
  async downloadFile({ commit }, url, vueContext) {
    commit('SET_IS_DOWNLOADING_FILE', true)
    const date_now = Date.now()
    const nombreArchivoLocal = 'img_' + date_now + '.jpg'

    try {
      const response = await BackendApi.post('/chat/convert_image_to_base_64', { url })
      
      if (response) {
        // Crear la URL base64 y descargar el archivo
        const urlBase64 = `data:${response.headers['content-type']};base64,${response.data}`
        const link = document.createElement('a')

        link.href = urlBase64
        link.setAttribute('download', nombreArchivoLocal)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      
    } catch (error) {
      CustomException.handleError({ error }, vueContext)

    } finally {
      commit('SET_IS_DOWNLOADING_FILE', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}