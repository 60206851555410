import BackendApi from '@/services/backend.service'
import { ValidationError, RequestError } from '@/services/errorHandler.service'
import CustomException from '@/services/customException'
import Vue from 'vue'

const state = {
  workgroupAgent: {
    user_id: null,
    agent_id: null,
    workgroup_id: null,
    company_name: null,
    company_phone: null,
    name: '',
    is_online: false
  },
  isFetchingTicketsDrawer: false,
  ticketsByStatus: getInitialTicketsByStage(),
  selectedTicketPhone: null,
  stageExpandedId: null,
  singleTicketLoaders: {
    'pending': false,
    'in_attention': false,
    'success': false,
    'failed': false
  }
}

const getters = {
  fetched_tickets_count: (state) => (stage_name) => {
    const stage = state.ticketsByStatus.find((stage) => stage.name === stage_name)

    return stage.pinned.length + stage.unpinned.length
  },
  selected_ticket_phone: (state) => {
    return state.selectedTicketPhone // Corrijo el nombre de la propiedad
  }
}

const mutations = {
  SET_WORKGROUP_AGENT(state, workgroupAgent) {
    state.workgroupAgent = workgroupAgent
  },
  SET_IS_FETCHING_TICKETS_DRAWER(state, isFetchingTicketsDrawer) {
    state.isFetchingTicketsDrawer = isFetchingTicketsDrawer
  },
  SET_TICKETS_BY_STATUS(state, ticketsByStatus) {
    state.ticketsByStatus = ticketsByStatus
  },
  SET_SELECTED_TICKET_PHONE(state, selectedTicketPhone) {
    state.selectedTicketPhone = selectedTicketPhone
  },
  SET_STAGE_EXPANDED_ID(state, stageExpandedId) {
    state.stageExpandedId = stageExpandedId
  },
  SET_STATUS_TICKETS_LOADER(state, { stage_name, is_loading }) {
    state.singleTicketLoaders[stage_name] = is_loading
  },
  UPDATE_AGENT_STATUS(state, newStatus) {
    if (newStatus !== 'online' && newStatus !== 'offline') {
      console.error('No se ha ingresado un status correcto', newStatus)
    } else {
      state.workgroupAgent.is_online = newStatus === 'online' ? 1 : 0
    }
  },
  ADD_TICKETS(state, { tickets, stage_name, add_type }) {
    const valid_names = ['pending', 'in_attention', 'success', 'failed']

    if (!tickets || !stage_name) throw new ValidationError('parametro no proporcionado', { tickets, stage_name, add_type })
    if (!valid_names.includes(stage_name)) throw new ValidationError('stage_name incorrecto', { stage_name, valid_names })

    const stage = state.ticketsByStatus.find((stage) => stage.name === stage_name)

    if (!stage) {
      console.error('stage no encontrado', { tickets, stage_name, add_type })

      return
    }

    if (add_type === 'unshift') {
      stage.pinned = [...tickets.pinned || [], ...stage.pinned]
      stage.unpinned = [...tickets.unpinned || [], ...stage.unpinned]
    } else {
      stage.pinned = [...stage.pinned, ...tickets.pinned || []]
      stage.unpinned = [...stage.unpinned, ...tickets.unpinned || []]
    }
  },
  ADD_TICKET(state, ticket) {
    const stage = state.ticketsByStatus.find((stage) => stage.status_id === ticket.status_id)

    if (ticket.is_pinned) {
      stage.pinned.push(ticket)
    } else {
      stage.unpinned.push(ticket)
    }
  },
  DELETE_TICKET(state, ticket) {
    const stage = state.ticketsByStatus.find((stage) => stage.status_id === ticket.status_id)
    const targetArray = ticket.is_pinned ? stage.pinned : stage.unpinned
    const index = targetArray.findIndex((t) => t.client.phone === ticket.client.phone)

    if (index !== -1) {
      targetArray.splice(index, 1)
    }
  },
  UPDATE_STAGE_COUNT(state, { stage_name, operation }) {
    const stage = state.ticketsByStatus.find((stage) => stage.name === stage_name)

    if (!stage) throw new ValidationError('cannot find stage', { stage_name, operation, stage })
    stage.count = stage.count + operation
  },
  SWITCH_TICKET_PIN_STATUS(state, { ticket }) {
    const stage = state.ticketsByStatus.find((stage) => stage.status_id === ticket.status_id)

    if (!stage) throw new ValidationError('No se ha encontrado el ticket', { stage, ticket })

    if (ticket.is_pinned) {
      const index = stage.pinned.findIndex((t) => t.client.phone === ticket.client.phone)

      if (index !== -1) {
        const [movedTicket] = stage.pinned.splice(index, 1)

        movedTicket.is_pinned = 0
        stage.unpinned.push(movedTicket)
      }
    } else {
      const index = stage.unpinned.findIndex((t) => t.client.phone === ticket.client.phone)

      if (index !== -1) {
        const [movedTicket] = stage.unpinned.splice(index, 1)

        movedTicket.is_pinned = 1
        stage.pinned.push(movedTicket)
      }
    }
  },
  UPDATE_DRAWER_TICKET_TAGS(state, { newTagsIds, ticket, tags }) {
    if (!newTagsIds || !ticket) throw new ValidationError({ newTagsIds, ticket })
    const stage = state.ticketsByStatus.find((stage) => stage.status_id === ticket.status_id)

    if (!stage) throw new ValidationError('No se ha encontrado el ticket', { stage, ticket })

    const ticketTags = tags.filter((tag) => newTagsIds.includes(tag.chat_tag_id))
    const targetArray = ticket.is_pinned ? stage.pinned : stage.unpinned
    const foundTicket = targetArray.find((t) => t.client.phone === ticket.client.phone)

    if (foundTicket) {
      foundTicket.client.tags = ticketTags
    }
  },
  SET_TICKETS_COUNTS(state, payload) {
    payload.forEach((stage_counts) => {
      const stage_tickets = state.ticketsByStatus.find((ticket) => ticket.name === stage_counts.name)

      stage_tickets.count = stage_counts.count
    })
  }
}

const actions = {
  async fetchWorkgroupAgent({ commit }, { vueContext }) {
    try {
      const { user_chat_rol, supervised_agent_id } = vueContext.$route.params
      const response = await BackendApi.post('/chat/workgroup_agent', { user_chat_rol, supervised_agent_id })

      if (!response?.data?.success || !response?.data?.data) {
        throw new RequestError(response, { chatRol, supervised_agent_id })
      }
      commit('SET_WORKGROUP_AGENT', response.data.data)
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
    }
  },
  async getPagedTicketsByStatus({ commit, state, getters }, { stage_name }, vueContext) {
    const { agent_id, workgroup_id } = state.workgroupAgent

    try {
      commit('SET_STATUS_TICKETS_LOADER', { stage_name, is_loading: true })
      if (!['pending', 'in_attention', 'success', 'failed'].includes(stage_name)) throw new ValidationError('No se ha proporcionado un stage_name correcto')
      if (!stage_name || !agent_id || !workgroup_id) throw new ValidationError('Se requiere el status_id, agent_id y workgroup_id ')

      const limit = 20
      const page = Math.ceil(getters.fetched_tickets_count(stage_name) / limit) + 1

      const response = await BackendApi.get('/chat/workgroup_agent_tickets', {
        params: {
          status_ids: getStatusIdsByNames([stage_name]),
          agent_id,
          workgroup_id,
          page,
          limit
        }
      })

      const tickets_data = response?.data?.data

      if (!tickets_data) throw new RequestError('El Request no ha devuelto data')
      const tickets = tickets_data.find((stage_tickets) => stage_tickets.name === stage_name).tickets

      if (tickets) commit('ADD_TICKETS', { tickets, stage_name, add_type: 'push' })
      commit('SET_STATUS_TICKETS_LOADER', { stage_name, is_loading: false })
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
      commit('SET_STATUS_TICKETS_LOADER', { stage_name, is_loading: false })
    }
  },
  async fetchTicketsDrawer({ commit, state, dispatch }, { vueContext }) {
    commit('SET_IS_FETCHING_TICKETS_DRAWER', true)
    try {
      const { agent_id, workgroup_id } = state.workgroupAgent

      commit('SET_TICKETS_BY_STATUS', getInitialTicketsByStage())
      const [response_counts] = await Promise.all([
        BackendApi.post('/chat/drawer_tickets_counts', { agent_id, workgroup_id }),
        dispatch('getAllTicketsByStatus', { stage_names: ['pending', 'in_attention'] })
      ])

      const counts = response_counts.data.data

      if (!counts) throw new Error('No se pudo obtener la data de los contadores')
      commit('SET_TICKETS_COUNTS', counts)
      commit('SET_IS_FETCHING_TICKETS_DRAWER', false)

      await Promise.all([
        dispatch('getPagedTicketsByStatus', { stage_name: 'success' }, vueContext),
        dispatch('getPagedTicketsByStatus', { stage_name: 'failed' }, vueContext)
      ])
    } catch (error) {
      commit('SET_IS_FETCHING_TICKETS_DRAWER', false)
      CustomException.handleError({ error }, vueContext)
    }
  },
  async searchDrawerTickets({ commit, state, dispatch }, { vueContext, input }) {
    commit('SET_IS_FETCHING_TICKETS_DRAWER', true)
    try {
      commit('SET_TICKETS_BY_STATUS', getInitialTicketsByStage())
  
      const { agent_id, workgroup_id } = state.workgroupAgent
      const status_ids = [1, 2, 3, 4] // pendientes, en atención, exitosos, fallidos
  
      const response = await BackendApi.get('/chat/workgroup_agent_tickets', {
        params: { status_ids, agent_id, workgroup_id, user_search_input: input }
      })
  
      if (!response?.data?.success || !response?.data?.data) {
        throw new RequestError(response)
      }
  
      // Formateamos los datos recibidos para ajustarlos a nuestra estructura
      response.data.data.forEach((stage) => {
        const pinned = stage.tickets?.pinned || []
        const unpinned = stage.tickets?.unpinned || []
  
        stage.pinned = pinned
        stage.unpinned = unpinned
  
        delete stage.tickets
      })
  
      commit('SET_TICKETS_BY_STATUS', response.data.data)
  
      commit('SET_IS_FETCHING_TICKETS_DRAWER', false)
    } catch (error) {
      commit('SET_IS_FETCHING_TICKETS_DRAWER', false)
      CustomException.handleError({ error }, vueContext)
    }
  },
  async getAllTicketsByStatus({ commit, state }, { stage_names }) {
    const { agent_id, workgroup_id } = state.workgroupAgent
    const status_ids = getStatusIdsByNames(stage_names)

    const response = await BackendApi.get('/chat/workgroup_agent_tickets', { params: { status_ids, agent_id, workgroup_id } })

    if (!response?.data?.success || !response?.data?.data) {
      throw new RequestError(response, { payload: { stage_names }, status_ids })
    }

    const ticketsByStatus = response.data.data
    const pending_tickets = ticketsByStatus.find((stage_tickets) => stage_tickets.name === 'pending').tickets
    const in_attention_tickets = ticketsByStatus.find((stage_tickets) => stage_tickets.name === 'in_attention').tickets

    if (pending_tickets) commit('ADD_TICKETS', { tickets: pending_tickets, stage_name: 'pending', add_type: 'push' })
    if (in_attention_tickets) commit('ADD_TICKETS', { tickets: in_attention_tickets, stage_name: 'in_attention', add_type: 'push' })
  },
  async openChatTicket({ commit, dispatch }, data) {
    const { ticket_phone, vueContext } = data

    try {
      commit('SET_SELECTED_TICKET_PHONE', ticket_phone)
      const ticket = await dispatch('findTicketByPhone', ticket_phone)

      if (!ticket_phone) { throw new ValidationError('No se ha proporcionado el phone', ticket_phone) }
      if (!ticket) { throw new ValidationError('No se logró encontrar el ticket por el phone', ticket) }

      ticket.chat.unreadMessageCount = 0
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
    }
  },
  deleteTicket({ commit }, ticket) {
    commit('DELETE_TICKET', ticket)

    return ticket
  },
  findTicketByPhone({ state }, phone) {
    phone = parseInt(phone)
    for (const stage of state.ticketsByStatus) {
      const foundInPinned = stage.pinned.find((ticket) => ticket.client.phone === phone)

      if (foundInPinned) return foundInPinned

      const foundInUnpinned = stage.unpinned.find((ticket) => ticket.client.phone === phone)

      if (foundInUnpinned) return foundInUnpinned
    }

    return null
  },
  listenNewMessage({ dispatch, state }, { channel }) {
    channel.unbind('message_' + state.workgroupAgent.agent_id)
    channel.bind('message_' + state.workgroupAgent.agent_id, async (eventData) => {
      const find_ticket = await dispatch('findTicketByPhone', eventData.message.message.client.phone)

      if (!find_ticket) throw new Error('No se pudo encontrar el ticket', { find_ticket })

      find_ticket.chat.last_message = eventData.message.message
      if (find_ticket.chat.last_message.type === 'inbound') find_ticket.chat.unreadMessageCount++
      //find_ticket.chat.secondsToExpirate++ // Aquí debería colocarse +24h cuando funcione lo de previsualizar si se le puede o no escribir
    })
  },
  listenNewTicket({ commit, dispatch, state }, { channel, vueContext }) {
    channel.unbind('new_ticket_' + state.workgroupAgent.agent_id)
    channel.bind('new_ticket_' + state.workgroupAgent.agent_id, async (newTicket) => {
      const foundTicket = await dispatch('findTicketByPhone', newTicket.client.phone)

      if (foundTicket && (foundTicket.status_id === 1 || foundTicket.status_id === 2)) {
        console.warning('ticket ya existente')

        return
      }

      commit('ADD_TICKET', newTicket)
      vueContext.$emit('show-new-conversation-notification')
    })
  },
  async UPDATE_DRAWER_TIKCET_ALIAS({ dispatch }, { new_alias, new_alias_id, ticket }) {
    const foundTicket = await dispatch('findTicketByPhone', ticket.client.phone)

    if (foundTicket) {
      Vue.set(foundTicket.client, 'alias', new_alias)
      Vue.set(foundTicket.client, 'alias_id', new_alias_id)
    }
  }
}

function getInitialTicketsByStage() {
  return [
    { pinned: [], unpinned: [], count: 0, status_id: 1, name: 'pending' },
    { pinned: [], unpinned: [], count: 0, status_id: 2, name: 'in_attention' },
    { pinned: [], unpinned: [], count: 0, status_id: 3, name: 'success' },
    { pinned: [], unpinned: [], count: 0, status_id: 4, name: 'failed' }
  ]
}

function findTicketByTicket(ticket) {
  const stage = state.ticketsByStatus.find((stage) => stage.status_id === ticket.status_id)
  
  if (!stage) throw new ValidationError('cannot find stage', { stage, ticket })
  const collection = ticket.is_pinned ? 'pinned' : 'unpinned'
  const foundTicket = stage[collection].find((t) => t.client.phone === ticket.client.phone)

  if (!foundTicket) throw new ValidationError('cannot find ticket')

  return foundTicket 
}

function getStatusIdsByNames(names) {
  if (!Array.isArray(names)) throw new ValidationError('Se espera un array en la función', { names })

  const status_list = { 'pending': 1, 'in_attention': 2, 'success': 3, 'failed': 4 }
  const ids = names.map((name) => status_list[name] || null)

  return ids
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
